import styled from "styled-components";
import { Container } from "../../components/base/Container";
import { Typography } from "../../components/base/Typography";
import { Row } from "../../components/base/Row";
import { Col } from "../../components/base/Col";
import logo from "../../img/logo.png";
import { Link } from "react-router-dom";
import { device } from "../../theme/mediaQuery";
import {
  BLOG,
  BUY,
  COMMUNITY,
  CRYPTO,
  WHITE_PAPER,
} from "../../consts/externalLinks";
import { LinkLabel } from "../../components/header/NavGamesMenu";

const NAV_GAMES = [
  // { title: "Tribes", path: CRYPTO.LAND_GAME },
  // { title: "Land Gameplay", path: CRYPTO.LAND_GAME },
  // { title: "Jousting", path: CRYPTO.JOUSTING },
  { title: "Shadowcorn", path: CRYPTO.SHADOWCORNS },
  // {
  //   title: "Unicorn Party",
  //   path: "/unicornparty",
  // },
  { title: "Rainbow Rumble", path: CRYPTO.RAINBOW_RUMBLE },
];

const NAV_MARKETPLACES = [
  {
    title: "Marketplace",
    path: BUY.MARKETPLACE.HOME,
  },
  // {
  //   title: "Land Vending Machine",
  //   path: CRYPTO.VENDING,
  // },
  {
    title: "CU Merch",
    path: CRYPTO.SHOP,
  },
];

const NAV_TOKENS = [
  {
    title: "Our Tokens",
    path: WHITE_PAPER.TOKENOMICS,
  },
  { title: "Staking", path: "/staking/overview" },
  {
    title: "Buy CU",
    path: BUY.CU,
  },
  {
    title: "Buy UNIM",
    path: BUY.UNIM,
  },
];

const NAV_COMMUNITY = [
  { title: "Governance", path: COMMUNITY.GOVERNANCE },
  { title: "Forums", path: COMMUNITY.FORMUS },
  { title: "Bug Bounty", path: "/bug-bounty" },
  { title: "News", path: BLOG.NEWS },
  { title: "UniWu", path: COMMUNITY.UNIWU, label: "community" },
  { title: "Join the Community", path: COMMUNITY.DISCORD },
];

const NAV_HELP = [
  {
    title: "ZENDESK SUPPORT",
    path: COMMUNITY.ZENDESK,
  },
  {
    title: "Learn About Wallets",
    path: WHITE_PAPER.STEP_1,
  },
  { title: "Discord Help", path: COMMUNITY.DISCORD },
  { title: "Licensing", path: "/licensing" },
];

const NAV = [
  { title: "Our Games", items: NAV_GAMES },
  { title: "Shop", items: NAV_MARKETPLACES },
  { title: "Our Tokens", items: NAV_TOKENS },
  { title: "Community", items: NAV_COMMUNITY },
  { title: "Help", items: NAV_HELP },
];

const FooterWrap = styled.footer`
  background-color: ${({ theme }) => theme.palette.white};
  // margin-top: 80px;
`;

const Copyright = styled.ul`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 32px 0;
  gap: 8px;

  flex-direction: column;

  @media (${device.lg}) {
    flex-direction: row;
  }
`;

const CopyrightLink = styled(Link)`
  text-decoration-color: ${({ theme }) => theme.palette.gray500};
`;

const CopyrightItemWrap = styled.li`
  &:nth-child(even) {
    &::after,
    &::before {
      display: none;
      content: "";
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background: ${({ theme }) => theme.palette.gray500};

      @media (${device.lg}) {
        display: inline-block;
      }
    }

    &::after {
      margin-left: 8px;
    }

    &::before {
      margin-right: 8px;
    }

    &:last-of-type::after {
      display: none;
    }
  }
`;

const LogoImage = styled.img`
  width: 60%;
  padding-bottom: 10px;
  display: block;
  margin: 0 auto;
  max-width: 385px;
  @media (${device.lg}) {
    margin: 0;
    width: 80%;
  }
`;

const FooterBox = styled.nav`
  padding: 50px 0;
`;

const LeadingWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 32px;
  text-align: center;

  @media (${device.lg}) {
    align-items: flex-start;
    margin-bottom: 0;
    text-align: left;
  }
`;

const NavWrap = styled.nav`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 32px;
  text-align: center;
  @media (${device.md}) {
    grid-template-columns: repeat(2, 1fr);
    text-align: left;
  }

  @media (${device.lg}) {
    gap: 15px;
    grid-template-columns: repeat(3, 1fr);
  }

  @media (${device.xl}) {
    gap: 20px;
    grid-template-columns: repeat(5, 1fr);
  }
`;

const NavList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const NavHeading = styled.h3`
  color: ${({ theme }) => theme.palette.darkBlue300};
  font-family: ${({ theme }) => theme.fonts.display};
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 16px;
  @media (${device.md}) {
    margin-bottom: 32px;
  }
`;

const NavLink = styled(Link)`
  display: flex;
  flex-direction: ${({ $hasLabel }) => ($hasLabel ? "column" : "row")};
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: ${({ theme }) => theme.palette.gray500};
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
  font-family: ${({ theme }) => theme.fonts.display};
  column-gap: 4px;

  @media (${device.md}) {
    justify-content: flex-start;
    flex-direction: row;
  }

  &:hover {
    color: ${({ theme }) => theme.palette.darkBlue300};
  }
`;

function CopyrightItem({ path, children }) {
  if (path) {
    return (
      <CopyrightItemWrap>
        <CopyrightLink to={path}>
          <Typography variant="textXs" tag="span" color="gray500" weight={700}>
            {children}
          </Typography>
        </CopyrightLink>
      </CopyrightItemWrap>
    );
  }
  return (
    <CopyrightItemWrap>
      <Typography variant="textXs" tag="span" color="gray500" weight={700}>
        {children}
      </Typography>
    </CopyrightItemWrap>
  );
}

export function Footer() {
  return (
    <FooterWrap>
      <Container>
        <FooterBox>
          <Row className="justify-content-between">
            <Col lg={3} xs={12}>
              <LeadingWrap>
                <Link to="/">
                  <LogoImage src={logo} alt="CryptoUnicorns Logo" />
                </Link>
                <Typography color="gray500" family="display" weight={400}>
                  A digital pet collecting and farming game, built on blockchain
                </Typography>
              </LeadingWrap>
            </Col>
            <Col lg={8} xs={12}>
              <NavWrap>
                {NAV.map((item) => (
                  <div key={item.title}>
                    <NavHeading>{item.title}</NavHeading>
                    <NavList>
                      {item.items.map((item) => (
                        <NavLink
                          target={
                            item.path.includes("https") ? "_blank" : "_self"
                          }
                          key={item.title}
                          to={item.path}
                          $hasLabel={item?.label}>
                          {item.title}
                          {item?.label && <LinkLabel variant={item.label} />}
                        </NavLink>
                      ))}
                    </NavList>
                  </div>
                ))}
              </NavWrap>
            </Col>
          </Row>
        </FooterBox>
        <Copyright>
          <CopyrightItem>Copyright 2024</CopyrightItem>
          <CopyrightItem path="/terms-of-service">
            Terms of Service
          </CopyrightItem>
          <CopyrightItem path="/privacy-policy">Privacy Policy</CopyrightItem>
          <CopyrightItem path="/ip-terms">IP Terms</CopyrightItem>
          <CopyrightItem path="mailto:contact@laguna.games">
            contact@laguna.games
          </CopyrightItem>
        </Copyright>
      </Container>
    </FooterWrap>
  );
}
